import * as React from "react";
import Button from "src/components/Button";
import PageHeader from "src/components/PageHeader";
import Typography from "src/components/Typography";
import {daysForDoneReview, SCENARIO_TYPES} from "src/consts";
import Offer from "src/routes/SendScenario/Offers/Offer";
import {Good} from "src/types";
import ScriptingOffer from "./ScriptingOffer/ScriptingOffer";
import classes from "./ScriptingOffers.module.css";
{/* tslint:disable:prefer-template */}

type P =  {goods: Good[]};

export const ScriptingOffers: React.SFC<P> = (props) => {

    const paulBraunColor = (
        <span
            style={{
                color: "#F06564",
            }}
        >
      ПОЛА БРАУНА
    </span>
    );

  return (
    <div className={classes.wrapper}>
      <PageHeader text="СКРИПТ-ДОКТОРИНГ" />
      <div className={classes.contentWrapper}>
      <Offer
         header={"Редакторское сопровождение"}
         subHeader={["Развернутая рецензия и 8 онлайн-встреч с редактором по доработке / улучшению сценария.", <Typography type="smallerText" key="возможна рассрочка">*возможна рассрочка - пишите на hello@potentialfilm.ru </Typography>]}
         good={props.goods.find((curr: Good) => curr.code === SCENARIO_TYPES.EDITORIAL_SUPPORT)}
         numberOfDays={daysForDoneReview[SCENARIO_TYPES.EDITORIAL_SUPPORT] + " МЕСЯЦЕВ"}
         type={SCENARIO_TYPES.EDITORIAL_SUPPORT.toLowerCase()}
      >
          <a
              href="https://storage.yandexcloud.net/potential-docs/development/rspp%20(1).pdf"
              target="blank"
              style={{ margin: "0 auto"}}
          >
              <Button>ПОДРОБНЕЕ</Button>
          </a>
      </Offer>

        <ScriptingOffer name={<div>СКРИПТ-ДОКТОРИНГ ОТ {paulBraunColor}</div>} />
        <ScriptingOffer
          name={
            <div>
              СКРИПТ-ДОКТОРИНГ ОТ {paulBraunColor}
              <br />
              ПЛЮС ОНЛАЙН-КОНСУЛЬТАЦИЯ
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ScriptingOffers;
