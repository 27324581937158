import { Link } from "gatsby";
import * as React from "react";
import Button from "src/components/Button";
import ScenarioOfferTemplate from "src/components/ScenarioOfferTemplate";
import classes from "./offer.module.css";
import { Good } from "src/types";

interface Props {
  numberOfDays: string;
  header: string;
  subHeader?: string | string[];
  good?: Good;
  disabled?: boolean;
  type: string;
}

const Offer: React.SFC<Props> = (props) => {
  const { good } = props;
    return (
    <ScenarioOfferTemplate
      header={props.header}
        // @ts-ignore
      subheader={[props.subHeader || "", props.subHeader ? <br key={2}/> : "", `СРОК ПОЛУЧЕНИЯ РЕЦЕНЗИИ — НЕ БОЛЕЕ ${props.numberOfDays} `].filter(Boolean)}
      price={props.good?.price}
      button={
          <div className={classes.button}>
            <Link
              to={`/review-form#${props.type}`}
              state={{
                modal: true,
                closeTo: "send-scenario",
                good: good,
              }}
            >
              <Button disabled={!good?._id} id={`#${props.type}`}>
                ПОЛУЧИТЬ
              </Button>
            </Link>
              {props.children}
          </div>
      }
    />
  );
};

export default Offer;
