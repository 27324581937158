import * as React from "react";
import Typography from "src/components/Typography";
import scenarioModuleCss from "./scenario.module.css";

const HeaderSection: React.SFC = () => {
  return (
    <div className={scenarioModuleCss.headerWrapper}>
      <div className={scenarioModuleCss.offerPageHeader}>
        <Typography type="h3">
          <h3>Получи рецензию на свой сценарий</h3>
        </Typography>
      </div>
      <div className={scenarioModuleCss.offerPageDescription}>
        <Typography type="text">
          Узнай сильные и слабые стороны своего сценария и получи возможность
          его доработать и улучшить.{" "}
          <a
            href="https://potentialfilm.ru/script_factory#editors"
            target="blank"
          >
            Наши редакторы
          </a>{" "}
          - это профессиональная команда отборщиков, которые знают, какие
          требования в киноиндустрии предъявляются к работе сценариста сегодня.
        </Typography>
      </div>
    </div>
  );
};

export default HeaderSection;
