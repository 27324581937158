import * as React from "react";
import { connect } from "react-redux";
import Api from "src/api";
import { daysForDoneReview, SCENARIO_TYPES } from "src/consts";
import { actions as userActions } from "src/store/User";
import { Good, User } from "src/types";
import Offer from "./Offer";
import styles from "./Offers.module.css";
{/* tslint:disable:prefer-template */}

interface ConnectDispatchProps {
  getUser(): typeof userActions.getUser;
}
interface ConnectStateProps {
  user?: User;
}

interface Props extends ConnectStateProps, ConnectDispatchProps {
  pageAddress: string;
  goods: Good[];
}

class Offers extends React.Component<Props> {

  public async componentDidMount() {
    this.props.getUser();
  }

  public render() {
    const { props } = this;
    return (
      <div>
        <div className={styles.offersWrapper}>
          <Offer
            header="КОРОТКИЙ МЕТР"
            good={this.findReviewGoods(props.goods, SCENARIO_TYPES.SHORT_FILM)}
            numberOfDays={daysForDoneReview[SCENARIO_TYPES.SHORT_FILM]  + " ДНЕЙ"}
            type={SCENARIO_TYPES.SHORT_FILM.toLowerCase()}
          />
          <Offer
            header={"ВЕБ СЕРИАЛ"}
            good={this.findReviewGoods(props.goods, SCENARIO_TYPES.WEB_SERIES)}
            numberOfDays={daysForDoneReview[SCENARIO_TYPES.WEB_SERIES]  + " ДНЕЙ"}
            type={SCENARIO_TYPES.WEB_SERIES.toLowerCase()}
          />
          <Offer
            header={"СЕРИАЛ (ПИЛОТ)"}
            good={this.findReviewGoods(
                props.goods,
              SCENARIO_TYPES.SERIES_PILOT
            )}
            numberOfDays={daysForDoneReview[SCENARIO_TYPES.SERIES_PILOT] + " ДНЕЙ"}
            type={SCENARIO_TYPES.SERIES_PILOT.toLowerCase()}
          />
          <Offer
            header={"ПОЛНЫЙ МЕТР"}
            good={this.findReviewGoods(
                props.goods,
              SCENARIO_TYPES.FEATURE_FILM
            )}
            numberOfDays={daysForDoneReview[SCENARIO_TYPES.FEATURE_FILM]  + " ДНЕЙ"}
            type={SCENARIO_TYPES.FEATURE_FILM.toLowerCase()}
          />
          <Offer
            header={"Редакторское заключение"}
            good={this.findReviewGoods(
                props.goods,
              SCENARIO_TYPES.EDITORIAL_OPINION
            )}
            numberOfDays={daysForDoneReview[SCENARIO_TYPES.EDITORIAL_OPINION]  + " ДНЕЙ"}
            type={SCENARIO_TYPES.EDITORIAL_OPINION.toLowerCase()}
          />
        </div>
      </div>
    );
  }

  private findReviewGoods(goods: Good[], reviewType: string): Good {
    return goods.find((curr: Good) => curr.code === reviewType);
  }
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user ? state.user.user : null,
  // tslint:enable no-unsafe-any
});

const mapActionsToProps = {
  getUser: userActions.getUser,
};

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(Offers);
