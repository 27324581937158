import React from "react";
import Seo from "src/components/Seo";
import SendScenario from "src/routes/SendScenario";

const SendScenarioPage = (props) => (
  <>
    <Seo
      pageTitle="Рецензирование"
      pageDescription="Рецензия на ваш сценарий поможет понять, какие ошибки вы допустили и как сделать историю сильнее и интереснее. Наши редакторы - это профессиональная команда, которая знает, какие требования в киноиндустрии предъявляются к работе сценариста сегодня."
    />
    <SendScenario {...props} />
  </>
);

export default SendScenarioPage;
