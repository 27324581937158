import * as React from "react";
import Button from "src/components/Button/Button";
import ScenarioOfferTemplate from "src/components/ScenarioOfferTemplate";

interface Props {
  name: React.ReactNode;
}

export const ScriptingOffer: React.SFC<Props> = (props) => {
  return (
    <ScenarioOfferTemplate
      header={props.name}
      subheader={["СРОК ДО 20 ДНЕЙ"]}
      button={
        <a
          href="https://storage.yandexcloud.net/potential-docs/production/%D0%9F%D0%91-2.pdf"
          target="blank"
        >
          <Button>ПОДРОБНЕЕ</Button>
        </a>
      }
    />
  );
};

export default ScriptingOffer;
