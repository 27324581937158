import * as React from "react";
import Api from "src/api";
import PageHeader from "src/components/PageHeader";
import RecommendedPlaylistsContainer from "src/components/RecommendedPlaylistsContainer";
import Typography from "src/components/Typography";
import { RECOMMENDED_PLAYLISTS_TYPES } from "src/consts";
import reviewMascot from "src/images/review-mascot.svg";
import {Good, PageProps} from "src/types";
import HeaderSection from "./HeaderSection";
import Offers from "./Offers";
import styles from "./scenario.module.css";
import ScriptingOffers from "./ScriptingOffers";

interface Props extends PageProps {}

const SendScenario = (props: Props) => {
  const [data, setData] = React.useState<Good[]>([]);

  React.useEffect(() => {
     Api.getGoodsReviews().then((res) => {
      setData(res.data);
    });
  }, []);


  return (
    <>
      <div>
        <PageHeader text="Рецензирование" />
        <div className={styles.description}>
          <div className={styles.textWrapper}>
            <HeaderSection />
            <div className={styles.texts}>
              <div>
                <Typography type="text">
                  Рецензия на ваш сценарий поможет понять, какие ошибки вы
                  допустили и как сделать историю сильнее и интереснее.
                </Typography>
                <Typography type="text">
                  В каждой рецензии редактор оценивает и анализирует:
                </Typography>
                <Typography type="text">
                  — идею сценария, актуальность и новизну темы, фабулу, сюжет и
                  конфликты;
                </Typography>
                <Typography type="text">
                  — работу с героями и развитие их характеров;
                </Typography>
                <Typography type="text">— структуру сценария;</Typography>
                <Typography type="text">
                  — инструменты сценарной записи и средства художественной
                  выразительности;
                </Typography>
                <Typography type="text">
                  — дает обратную связь: как можно улучшить сценарий.
                </Typography>
              </div>
              <br />
              <br />
              <div>
                <Typography type="text">
                  Редакторское заключение — возможность получить обратную связь
                  о вашем сценарии от продюсера или редактора.
                </Typography>
                <Typography type="text">
                  Что включает в себя редакторское заключение:
                </Typography>
                <Typography type="text">
                  — рецензию на ваш сценарий (полный метр или пилот сериала)
                </Typography>
                <Typography type="text">
                  — онлайн-консультации с редактором или продюсером, где вы
                  обсудите преимущества и недостатки проекта, возможные пути его
                  финансирования и реализации.
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={reviewMascot} alt="mascot potential" />
          </div>
        </div>
        <Offers pageAddress={props.location.pathname} goods={data} />
      </div>
      <ScriptingOffers goods={data}/>
      <RecommendedPlaylistsContainer
        type={RECOMMENDED_PLAYLISTS_TYPES.SCENARIO_PAGE}
      />
    </>
  );
};

export default SendScenario;
