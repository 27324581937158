import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./ScenarioOfferTemplate.module.css";

interface Props {
  header: React.ReactNode;
  subheader?: string[];
  price?: number;
  button: React.ReactNode;
}

export const ScenarioOfferTemplate: React.SFC<Props> = (props) => {
  return (
    <div className={styles.singleOffer}>
      <div className={styles.offerHeader}>
        <Typography type={"h4"}>
          <h4>{props.header}</h4>
        </Typography>
      </div>
        <div  className={styles.offerHeaders}>
      {props.subheader?.length ?
          props.subheader.map(curr => <Typography key={curr} type="text">{curr}</Typography>) : null
      }
        </div>
      {props.price && (
        <div className={styles.price}>
          <Typography type="boldText">
            {`${new Intl.NumberFormat("ru").format(props.price / 100)} Р`}
          </Typography>
        </div>
      )}
      <div className={styles.button}>{props.button}</div>
    </div>
  );
};

export default ScenarioOfferTemplate;
